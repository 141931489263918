<template>
	<div id="usuarios">
		<div class="tabela-usuario">
			<CustomTable 
				v-if="headers != ''"
				:action="'getUsuarios'"
				:getter="$store.getters.usuarios"
				:headers="headers"
				:search="true"
				:title="'Usuários'"
				:icone="'fas fa-users'"
				:pagination="true"
				:filters="filtros"
				ref="tabela"
			>
				<template v-slot:companies="{ item }" >
					<div v-if="item.companies">
						<span v-for="(company, i) in item.companies" :key="i">
							{{company.name}}
							{{((item.companies.length - 1) > i) ? ', ' : ''}}
						</span>
					</div>
				</template>
				<template v-slot:status="{ item }">
					<span v-if="item.status">
						Ativo
						<img src="./../../assets/images/icon-ativo.png">
					</span>
					<span v-else>
						Inativo
						<img src="./../../assets/images/icon-inativo.png">
					</span>
				</template>
				<template v-slot:departments="{ item }" >
					<div v-if="item.departments">
						<span v-for="(department, i) in item.departments" :key="i">
							{{department.name}}
							{{((item.departments.length - 1) > i) ? ', ' : ''}}
						</span>
					</div>
				</template>
				<template v-slot:acoes="{ item }">
                    <router-link :to="{ path: '/chat/'+item.id, params: { user_id: item.id } }" :disabled="$store.getters.company_id == null ? true : false" :event="$store.getters.company_id == null ? '' : 'click'">
                        <v-btn class="primary-button" raised small @click="editarUsuario(item.id)" :disabled="$store.getters.company_id == null ? true : false">
                            <!-- <i class="fas fa-cog"></i> -->
                            Ver Chat
                        </v-btn>
                    </router-link>
				</template>
			</CustomTable>
			<Loader v-if="loading"/>
		</div>
	</div>
</template>

<script>
	// importa o store
	import store from '@/store'
	// importa o componente de Loader 
	import Loader from '@/components/Loader.vue'
	// importa o componente de CustomTable 
	import CustomTable from '@/components/CustomTable.vue'
	// importa o plugin de request 
	import Request from '@/plugins/request'

	import API from '@/plugins/api'

	// exporta o componente
	export default {
		// nome do componente
		name: 'ChatUsuarios',
		// componentes filhos
		components: {
			// componente de CustomTable
			CustomTable,
			// componente de Loader
			Loader,
		},
		// dados do componente
		data: () => ({
			// variável para mostrar a modal para editar/criar um usuário
			dialog_usuario: false,
			// variável para mostrar a modal de informação
			dialog_resposta: false,
			// variável para a mensagem de resposta
			resposta: {},
			// variável para o loading
			loading: false,
			// variável para os grupos
			grupos: [],
			// variável para os setores
			setores: [],
			// variável para as empresas
			empresas: [],
			// variável para criar/editar usuário
			usuario: {
				usuario_id: '',
				nome: '',
				email: '',
				repete_email: '',
				senha: '',
				repete_senha: '',
				grupo: '',
				setor: [],
				empresa: [],
				status: '',
			},
			// variável para o cabeçalho da tabela
			headers: [
				{
					value: 'name',
					text: 'Nome',
					sortable: true,
				},
				{
					value: 'group.name',
					text: 'Grupo',
					sortable: true,
				},
				{
					value: 'departments',
					text: 'Setor',
					sortable: true,
				},
				{
					value: 'companies',
					text: 'Empresa(s)',
					sortable: true,
				},
				{
					value: 'status',
					text: 'Status',
					sortable: true,
				},
				{
					value: 'acoes',
					sortable: false,
					text: 'Ações',
				},
			],
			// variável para os filtros da tabela
			filtros: {
				perPage: 20,
                department_id: null,
			},
		}),
		// funções deste componente
		methods: {
			// funções de incio do componente
			async createUser(){
				this.dialog_usuario = await true

				this.loading = await true
				// função para pegar as empresas
				// this.getEmpresas()
				// função para pegar os setores
				this.getSetores()
				// função para pegar os grupos
				this.getGrupos()
				
				this.loading = await false
			},
			// função para pegar as empresas
			async getEmpresas(){
				// faz a requisição para o back para coletar as empresas
				var resp = await Request({
					// rota da requisição
					route: '/empresa/read'
				})
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel empresas
					this.empresas = resp.data
				}
			},
			// função para pegar os setores
			async getSetores(){
				// faz a requisição para o back para coletar as telefones
				const resp = await store.dispatch('getSetoresSelect')
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel telefones
					this.setores = resp.data.departments
				}
			},
			// função para pegar os grupos
			async getGrupos(){
				// faz a requisição para o back para coletar os grupos
				const { data, status } = await API.get('user-group?orderBy=id&order=asc&_embed=true')
				// caso o status da resposta seja 200 (deu certo)
				if(status == 200){
					// atribui a resposta na variavel grupos
					this.grupos = data.user_groups
				}
			},
			// função para enviar um usuário
			async enviarUsuario(){
				let resp = {}
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_usuario.validate()){
					// coloca o componente como loading
					this.loading = await true
					// coloeta os dados do usuário
					let dados = await {
						// coleta o nome do usuário
						name: this.usuario.nome,
						// coleta o email do usuário
						email: this.usuario.email,
						// coleta o email do usuário
						email_confirmation: this.usuario.repete_email,
						// coleta a senha do usuário
						password: this.usuario.senha,
						// coleta a senha do usuário
						password_confirmation: this.usuario.repete_senha,
						// coleta o grupo do usuário
						group: this.usuario.grupo,	
						// coleta o setor do usuário
						departments: this.usuario.setor,	
					}
					// caso exista um usuario_id 
					if(this.usuario.usuario_id){
						// coleta o status do empresa
						dados.status = await this.usuario.status == 'Ativo' ? true : false

						let date_update = await {
							dados_usuario: dados,
							id: this.usuario.usuario_id
						}

						resp = await store.dispatch('putUser', date_update)
					}else{
						// faz a requisição para o back para coletar os grupos
						resp = await store.dispatch('postUsuarios', dados)
					}
					// caso o status da resposta seja 200 (deu certo)
					if(resp.status != 200 && resp.status != 201){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error
						// mostra a mensagem
						this.dialog_resposta = true
					// caso tenha dado algum erro
					}else{
						// atribui o título da mensagem 
						this.resposta.titulo = await  'Usuário ' +  (this.usuario.usuario_id ? 'editado!' : 'cadastrado!')
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await 'Usuário ' + (this.usuario.usuario_id ? 'editado' : 'cadastrado') + ' com sucesso!'
						// mostra a mensagem
						this.closeUsuario()
						// fecha a modal de create/edit usuário
						this.dialog_resposta = true
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
				}
			},
			// função para coletar um usuário para editar
			async editarUsuario(usuario_id){
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar um usuário passando o id 
				var resp = await store.dispatch('getUsuario', usuario_id)
				// caso o status da resposta seja 200 (deu certo) 
				if(resp.status == 200){
					// atribui os dados do usuário vindos do back à váriável local
					this.usuario.usuario_id = await resp.data.id || ''
					this.usuario.nome = await resp.data.name || ''
					this.usuario.email = await resp.data.email || ''
					this.usuario.grupo = await resp.data.group.key || ''
					this.usuario.setor = (resp.data.departments || []).map(department => department.id)
					this.usuario.status = await resp.data.status ? 'Ativo' : 'Inativo'
					// mostra a modal de creat/edit usuário
					this.createUser()
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				// atualiza a tabela
				this.$refs.tabela.init()
			},
			// função que roda quando é fechada a modal de create/edit usuário
			closeUsuario(){
				// fecha a modal
				this.dialog_usuario = false
				// limpa os dados locais do usuário
				this.usuario =  {
					usuario_id: '',
					nome: '',
					email: '',
					repete_email: '',
					senha: '',
					repete_senha: '',
					grupo: '',
					setor: '',
					empresa: [],
					status: '',
				}
			},
			limiter() {
				// coleta o grupo que está selecionado
				var grupo_selecionado = this.grupos.find(grupo => this.usuario.grupo == grupo._id)
				// caso seja atendente
				if(grupo_selecionado.tipo == 'atendente'){
					// verifica se tem mais de 1 setor
					if(this.usuario.setor.length > 1) {
						// deixa somente 1 setor
						this.usuario.setor = [this.usuario.setor[0]]  
					}
				}
			},
		},
		// funções que rodam quando o componente é montado
		mounted(){
			// função de início do componente
			// this.init()
            this.filtros.department_id = this.$route.params.id;
		},
		// variaveis computadas em tempo real
		computed: {
			// função para a regra de validação do campo de setor
			conputed_rule_setor(){
				// coleta o grupo que está selecionado
				var grupo_selecionado = this.grupos.find(grupo => this.usuario.grupo == grupo._id)
				// caso exista um grupo selecionado
				if(grupo_selecionado){
					// caso o grupo selecionado seja superadmin 
					if(grupo_selecionado.tipo == 'superadmin' || grupo_selecionado.tipo == 'admin'){
						// retorna true (válido)
						return true
					// caso o grupo selecionado não seja superadmin 
					}else{
						// caso setor não for null
						if(this.usuario.setor){
							// retorna true (válido)
							return true
						// caso setor for null
						}else{
							// retorna false (inválido)
							return false
						}
					}
				// caso não exista um grupo selecionado
				}else{
					// retorna false (inválido)
					return false
				}
			},
			// função para a regra de validação do campo de empresas
			conputed_rule_empresas(){
				// coleta o grupo que está selecionado
				var grupo_selecionado = this.grupos.find(grupo => this.usuario.grupo == grupo._id)
				// caso exista um grupo selecionado
				if(grupo_selecionado){
					// caso o grupo selecionado seja superadmin 
					if(grupo_selecionado.tipo == 'superadmin'){
						// retorna true (válido)
						return true
					// caso o grupo selecionado não seja superadmin
					}else {
						// caso empresa não for null 
						if(this.usuario.empresa.length > 0){
							// retorna true (válido)
							return true
						// caso empresa for null 
						}else{
							// retorna false (inválido)
							return false
						}
					}
				// caso não exista um grupo selecionado
				}else{
					// retorna false (inválido)
					return false
				}
			}
		}
	}
</script>

<style lang="scss">
	#usuarios{
		display: flex;
		max-height: 100%;
		padding: 24px;
		flex-wrap: wrap;
		.tabela-usuario{
            width: 100%;
			background-color: #fff;
    		border-radius: 10px;
			.novo-usuario{
				display: flex;
				justify-content: flex-end;
				padding-bottom: 0;
				button{
					i, svg{
						margin-right: 10px;
					}
					text-transform: capitalize;
				}
			}
		}
	}
	.v-dialog{
		.headline{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-weight: 600;
			color: #11263C;
		}
		.v-card{
			background-color: #f9f9f9;
		}
		.v-card__title{
			span{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				font-weight: 600;
				color: rgba(17, 38, 60, 1);
			}
			svg{
				margin-left: 15px;
				path{
					fill: rgba(17, 38, 60, 1);
				}
			}
		}
		.v-text-field__details{
			display: none;
		}
		.div-input{
			padding: 5px;
		}
	}
</style>